import { Injectable } from '@angular/core';

import { IResultHttpApp } from '../interfaces/IResultHttpApp';

import { environment } from '../environments/environment';
import { UsuarioModel } from '../models/usuarioModel';
import { GuiaTurismoModel } from '../models/guiaTurismoModel';
import { HttpAppService } from './http-app.service';
import { BaseAppService } from './base-app.service';
import { ClienteModel } from 'src/models/clienteModel';
import { ControleLoginAppService } from './controle-login-app.service';

@Injectable({
  providedIn: 'root'
})
export class UsuarioAppService extends BaseAppService<UsuarioModel> {

  private _usuarioLogado: UsuarioModel = new UsuarioModel();

  constructor(
    public override http: HttpAppService,
    private controleLoginAppSrv: ControleLoginAppService
  ) {
    super('usuario', http);
  }

  getEmail(edEmailUsuario: string, flNaoUsarSpinner?: number, msgSpinner?: string): Promise<IResultHttpApp> {
    return this.http.get(`${environment.url_api}/usuario/email/${edEmailUsuario}`, flNaoUsarSpinner, msgSpinner);
  }

  getUsuariosAdmin(flNaoUsarSpinner: number): Promise<IResultHttpApp> {
    return this.http.get(`${environment.url_api}/usuario/getUsuariosAdmin/all`, flNaoUsarSpinner);
  }

  getUsuarioLogadoApi(uidUsuario: string, flNaoUsarSpinner: number): Promise<IResultHttpApp> {
    return this.http.get(`${environment.url_api}/usuario/getUsuarioLogadoApi/${uidUsuario}`, flNaoUsarSpinner);
  }

  login(edEmailUsuario: string, dlSenhaUsuario: string): Promise<IResultHttpApp> {
    return this.http.post(`${environment.url_api}/usuario/login`,
      { edEmailUsuario, dlSenhaUsuario }, undefined, undefined, 'Aguarde...');
  }

  altSenha(edEmailUsuario: string, dlSenhaUsuario: string, dlNovaSenha: string, dlConfirmNovaSenha: string): Promise<IResultHttpApp> {
    return this.http.post(`${environment.url_api}/usuario/altSenha`, { edEmailUsuario, dlSenhaUsuario, dlNovaSenha, dlConfirmNovaSenha }, undefined, undefined, 'Aguarde...');
  }

  esqueciSenha(edEmailUsuario: string): Promise<IResultHttpApp> {
    return this.http.post(`${environment.url_api}/usuario/esqueciSenha`,
      { edEmailUsuario }, undefined, undefined, 'Aguarde...');
  }

  recuperaSenha(edEmailUsuario: string, dlNovaSenha: string, dlConfirmNovaSenha: string): Promise<IResultHttpApp> {
    return this.http.post(`${environment.url_api}/usuario/recuperaSenha`, { edEmailUsuario, dlNovaSenha, dlConfirmNovaSenha },
      undefined,
      undefined,
      'Aguarde...');
  }

  confirmaCadastro(uid: string): Promise<IResultHttpApp> {
    let _flNaoUsarSpinner = 1;
    return this.http.post(`${environment.url_api}/usuario/confirmaCadastro`,
      { uid },
      _flNaoUsarSpinner);
  }

  async ObterLogadoAPI(uidUsuario: string): Promise<UsuarioModel> {
    let _flNaoUsarSpinner = 1;
    let _retUsuLogado = await this.getUsuarioLogadoApi(uidUsuario, _flNaoUsarSpinner);
    if (_retUsuLogado && _retUsuLogado.success) {
      const { Usuario } = _retUsuLogado.data;
      this.controleLoginAppSrv.GuardarUsuario(<UsuarioModel>Usuario);
      const { GuiaTurismo } = _retUsuLogado.data;
      this.controleLoginAppSrv.GuardarGuia(<GuiaTurismoModel>GuiaTurismo);
      const { Cliente } = _retUsuLogado.data;
      this.controleLoginAppSrv.GuardarCliente(<ClienteModel>Cliente);
      this.controleLoginAppSrv.forcarRepublicarUsuario();
      return <UsuarioModel>Usuario;
    } else {
      this.controleLoginAppSrv.GuardarUsuario(new UsuarioModel());
      this.controleLoginAppSrv.GuardarGuia(new GuiaTurismoModel());
      this.controleLoginAppSrv.GuardarCliente(new ClienteModel());
      return new UsuarioModel();
    }
  }

}
